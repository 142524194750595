<template>
  <div class="up-lecture">
    <template v-for="a in lectureData.attachments">
      <template v-if="isPdf(a)">
        <el-button
          v-if="a.viewEmbedded"
          :key="a.id"
          class="up-lecture__file-btn"
          type="text"
          @click="openModal(a)"
        >
          <PdfIcon class="up-lecture__icon" /> {{ a.name }}
        </el-button>
        <a
          v-else
          :key="a.id"
          :href="getFileLink(a)"
          class="up-lecture__file-btn"
          download
        >
          <PdfIcon class="up-lecture__icon" /> {{ a.name }}
        </a>
      </template>
      <a
        v-else
        :key="a.id"
        :href="getFileLink(a)"
        class="up-lecture__file-btn"
        download
      >
        <WordIcon class="up-lecture__icon" /> {{ a.name }}
      </a>
    </template>
    <div class="ql-snow up-lecture__content">
      <div class="ql-editor" v-html="lectureData.content" />
    </div>
    <el-dialog
      center
      modal-centered
      title="Просмотр файла"
      :visible.sync="modalVisible"
      custom-class="up-modal-show-file"
    >
      <a class="up-modal-show-file__download-btn" :href="fileSrc" download>
        <PdfIcon class="up-lecture__icon" /> Скачать файл
      </a>
      <iframe
        :src="`https://drive.google.com/viewerng/viewer?embedded=true&url=${fileSrc}#toolbar=0&scrollbar=0`"
      />
    </el-dialog>
  </div>
</template>

<script>
import PdfIcon from "@/components/icons/Pdf";
import WordIcon from "@/components/icons/Word";

export default {
  name: "Lecture",
  props: {
    lectureData: Object,
  },
  components: { PdfIcon, WordIcon },
  data: () => ({
    modalVisible: false,
    fileSrc: "",
  }),
  methods: {
    isPdf(attachment) {
      return attachment.contentType.includes("application/pdf");
    },
    getFileLink(attachment) {
      return `${process.env.VUE_APP_SERVER_URL}/constructor/attachment/${attachment.id}`;
    },
    openModal(attachment) {
      this.fileSrc = this.getFileLink(attachment);

      setTimeout(() => (this.modalVisible = true), 300);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/listener/course/lecture.scss";
@import "~quill/dist/quill.snow.css";
</style>